<template>
  <el-page-header @back="goBack">
    <template #content>
      <div class="avatar-info">
        <!-- <img src="@/assets/jingyu.png" alt="avatar" class="avatar-image" /> -->


        <el-popover
      :width="300"
      popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
    >
      <template #reference>
        <el-avatar src="http://diagapi.wwftool.top/api/uploads/tmp/00/jingyu.b7009034.png" :size="60"/>
      </template>
      <template #default>
        <div
          class="demo-rich-conent"
          style="display: flex; gap: 16px; flex-direction: column"
        >
          <el-avatar
            :size="60"
            src="http://diagapi.wwftool.top/api/uploads/tmp/00/jingyu.b7009034.png"
            style="margin-bottom: 8px"
          />
          <div>
            <p
              class="demo-rich-content__name"
              style="margin: 0; font-weight: 500"
            >
              测试中,年后开放相关功能
            </p>
            <p
              class="demo-rich-content__mention"
              style="margin: 0; font-size: 14px; color: var(--el-color-info)"
            >
              未开发
            </p>
          </div>

          <p class="demo-rich-content__desc" style="margin: 0">
            添加替换头像,修改用户名称,密码,发布需求留言等功能...
          </p>
        </div>
      </template>
    </el-popover>

        <div class="avatar-text-container">
          <span>{{ userData?.job }}</span>
          <span style="color: var(--el-text-color-regular)">{{ userData?.username }}</span>
          <el-tag>{{ userData?.webstatus }}</el-tag>
        </div>
      </div>
    </template>
    <template #extra>
      <div class="custom-style">
        <el-segmented v-model="val1" :options="options" size="large" @change="handleSegmentChange" />
        <Drawerlog v-model="showDrawer" @update:modelValue="handleDrawerChange" />
        <ApiAnalysis v-model="showApiAnalysis" @update:modelValue="handleApiAnalysisChange" />
      </div>

    </template>

  </el-page-header>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Drawerlog from './Drawerlog.vue';//更新内容
import ApiAnalysis from './ApiAnalysis.vue';//在线接口类型解析
export default {
  components: {
    Drawerlog, // 在这里注册 Drawerlog 组件
    ApiAnalysis,
  },
  setup() {
    const showDrawer = ref(false)
    const showApiAnalysis = ref(false)
    const val1 = ref('webford')
    const options = [
      {
        label: 'webford',
        value: 'webford',
      },
      {
        label: '在线接口类型解析',
        value: '1',
      },
      {
        label: '接口文档',
        value: '2',
        disabled: true
      },
      {
        label: '更新内容',
        value: '3',
      },
      {
        label: '查询设置',
        value: '4',
        disabled: true
      }]
    const store = useStore();
    const userData = computed(() => store.state.userData);
    const router = useRouter();
    const goBack = () => {
      // 假设你使用了 Vue Router，并且 router 已经被正确设置
      router.go(-1);
    };

    const handleDrawerChange = (newValue) => {
      showDrawer.value = newValue;
      if (!newValue) {
        // 如果需要，在这里更新 val1 的值  
        val1.value = 'webford'; // 更新为所需的值  
      }
    };
    const handleApiAnalysisChange = (newValue) => {
      showApiAnalysis.value = newValue;
      if (!newValue) {
        val1.value = 'webford';
      }
    };

    const handleSegmentChange = (value) => {
      if (value === '3') {
        showDrawer.value = true;
      }
      else if (value === '1') {
        showApiAnalysis.value = true;
      }
      else {
        showApiAnalysis.value = false;
        showDrawer.value = false;
      }
    };


    return {
      userData,
      goBack,
      val1, options,
      handleSegmentChange, handleDrawerChange,handleApiAnalysisChange,
      showDrawer,showApiAnalysis
    };

  }
};
</script>

<style scoped>
.custom-style .el-segmented {
  --el-segmented-item-selected-color: var(--el-text-color-primary);
  --el-segmented-item-selected-bg-color: #ffd100;
  --el-border-radius-base: 12px;
}

.avatar-info {
  display: flex;
  align-items: center;
  width: 100%;
  /* 垂直居中对齐 */
}

.avatar-text-container {
  display: flex;
  flex-direction: column;
  /* 设置为列方向，如果需要的话 */
  margin-left: 10px;
  /* 添加一些间距来分开头像和文字 */
}

.avatar-image {
  width: 60px;
  /* 调整为你需要的尺寸 */
  height: 60px;
  /* 调整为你需要的尺寸 */
  border-radius: 100%;
  /* 圆形头像 */
}


/* 其他样式可以根据需要进行调整 */
</style>