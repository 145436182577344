<template>
    <el-container class="main-container">
        <el-main class="data-container">

            <div class="serial-number-container">
                <el-autocomplete v-model="inputvin" :fetch-suggestions="querySearch" clearable
                    class="inline-input w-100" placeholder="Please input vin" @select="handleSelect" maxlength="17"
                    show-word-limit type="text" />
                <el-button type="primary" @click="fetchData">查询</el-button>
            </div>

            <el-tabs v-model="radio1" class="demo-tabs" @tab-click="handleClick">
                <el-tab-pane label="CN Server" name="CN Server"></el-tab-pane>
                <el-tab-pane label="US Server" name="US Server"></el-tab-pane>

                <el-row v-loading="loading">

                    <el-col :span="4">
                        <el-statistic title="未打包数" :value="PendingCnt" />

                        <el-popconfirm title="执行数据打包1次(最多2000*10个Vin)" @confirm="handleCmdUsingXmlTool()">
                            <template #reference>
                                <el-button size="small" :disabled="userData?.webstatus !== 'SSVIP'" type=""
                                    :icon="FolderAdd" circle />
                            </template>
                        </el-popconfirm>
                    </el-col>

                    <el-col :span="4">
                        <el-statistic title="已打包数" :value="PackedCnt" />
                    </el-col>

                    <el-col :span="4">
                        <el-statistic title="已上传数" :value="UploadedCnt" />
                    </el-col>

                    <el-col :span="4">
                        <el-statistic title="压缩包数" :value="ZipCnt" />
                    </el-col>

                    <el-col :span="4">
                        <el-statistic title="是否运行中" :value="running" :value-style="{
                            color: running === 'True' ? '#61bc84' : '#fd5732',
                            fontFamily: 'Poppins, Arial, sans-serif',
                            fontWeight: 'bold',
                            fontSize: '18px',
                            fontStyle: 'PingFang SC'
                        }" />
                    </el-col>
                </el-row>

                <!-- <el-row v-if="isVisible"> <el-col :span="24"></el-col></el-row> -->
                <el-row :gutter="0" style="margin-top: 15px;" v-if="isVisible">

                    <el-table :data="tableData" v-loading="loading2">
                        <el-table-column v-for="column in columns" :key="column.prop" :prop="column.prop"
                            :label="column.label" :width="column.width" header-align="center" align="center" />
                        <el-table-column>
                            <!-- <template #header>
                                <el-input v-model="search" size="small" placeholder="VIN筛选" />
                            </template> -->
                            <template #default="scope">

                                <el-popconfirm title="删除服务器上传记录" @confirm="handleDeleteRecord(scope.$index, scope.row)">
                                    <template #reference>
                                        <el-button size="small" type="danger" :loading="btLoading"
                                            style="min-width: 60px;">删除记录</el-button>
                                    </template>
                                </el-popconfirm>

                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>

                <el-row> <el-col :span="24"></el-col></el-row>
                <el-row :gutter="0" style="margin-top: 15px;">

                    <el-table :data="tableData2" v-loading="loading">
                        <el-table-column v-for="column2 in columns2" :key="column2.prop" :prop="column2.prop"
                            :label="column2.label" :width="column2.width" header-align="center" align="center" />
                        <el-table-column>
                            <!-- <template #header>
                                <el-input v-model="search" size="small" placeholder="VIN筛选" />
                            </template> -->
                            <template #default="scope">

                                <el-popconfirm title="下载压缩包" @confirm="handleDownLoad(scope.$index, scope.row)">
                                    <template #reference>
                                        <el-button size="small" type="success" :loading="btLoading2"
                                            :disabled="userData?.webstatus !== 'SSVIP'"
                                            style="min-width: 60px;">下载</el-button>
                                    </template>
                                </el-popconfirm>

                                <el-popconfirm title="确认上传后,将不可再下载。" @confirm="handleConfirm(scope.$index, scope.row)">
                                    <template #reference>
                                        <el-button size="small" type="success"
                                            :disabled="!canUpload(scope.$index)"
                                            style="background-color: #71c4ef;min-width: 60px;">标记上传</el-button>
                                    </template>
                                </el-popconfirm>


                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>

            </el-tabs>
        </el-main>
    </el-container>
</template>



<script>


import { ref, computed, onMounted, h } from 'vue';
import { useStore } from 'vuex';
import { ElMessage, ElNotification } from 'element-plus'
import { getPackedInfo, MovePackedInfo, getVinUploadInfo, deleteVinUploadInfo, DownLoadZip, CmdUsingXmlTool } from '../api/users';
import { FolderAdd } from '@element-plus/icons-vue';
export default {
    setup() {

        const store = useStore();
        const userData = computed(() => store.state.userData);

        // 列配置，这里你可以根据需要动态设置或静态定义  
        const isVisible = ref(false);
        const columns = ref([
            { "prop": "vin", "label": "Vin", "width": "200" },
            { "prop": "exist", "label": "是否上传", "width": "120" },
            { "prop": "time", "label": "上传时间", "width": "180" },
        ]);
        const tableData = ref([]);
        const columns2 = ref([
            { "prop": "zip", "label": "压缩包", "width": "250" },
            { "prop": "num", "label": "文件数", "width": "150" },
        ]);
        const tableData2 = ref([]);
        const uploadedRows = ref([]);// 存储已上传的行的标记
        const search = ref('');

        const cityData = ref({
            'CN Server': [],
            'US Server': []
        });

        const radio1 = ref('CN Server')
        const PendingCnt = ref(0); // 未打包数
        const PackedCnt = ref(0); // 已打包数  
        const UploadedCnt = ref(0); // 已上传数  
        const ZipCnt = ref(0);
        const running = ref('未知');

        const loading = ref(false)  // 定义 按钮请求网络时等待的 状态
        const loading2 = ref(false)
        const btLoading = ref(false)
        const btLoading2 = ref(false)
        const inputvin = ref('');
        const currentPage = ref(1);
        const pageSize = ref(20); // 每页显示N条数据为例  

        const isValidLength = ref(true);
        const recentQueries = ref(JSON.parse(localStorage.getItem('recentQueriesDataSwitch')) || []);

        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        const paginatedData = computed(() => {
            const start = (currentPage.value - 1) * pageSize.value;
            const end = currentPage.value * pageSize.value;
            return cityData.value[radio1.value].slice(start, end);
        });

        //相应函数
        const handleClick = async (tab, event) => {
            loading.value = true;
            const useBaseURL = (tab.paneName === 'CN Server') ? true : false;
            const response = await getPackedInfo(useBaseURL);

            PendingCnt.value = response.data.PendingCnt;
            PackedCnt.value = response.data.PackedCnt;
            UploadedCnt.value = response.data.UploadedCnt;
            ZipCnt.value = response.data.ZipCnt;
            running.value = response.data.running;
            columns2.value = response.data.columns;
            tableData2.value = response.data.tableData;

            loading.value = false;
            uploadedRows.value = [];
        }

        // 组件挂载时发起请求  
        onMounted(async () => {
            loading.value = true;
            try {
                if (store.state.userData?.webstatus !== 'SSVIP') {
                    ElNotification({
                        title: '注意',
                        message: h('i', { style: 'color: #fd5732' }, '数据转存权限暂不开放!!!'),
                        type: 'warning',
                    })
                }

                const response = await getPackedInfo(true);
                PendingCnt.value = response.data.PendingCnt;
                PackedCnt.value = response.data.PackedCnt;
                UploadedCnt.value = response.data.UploadedCnt;
                ZipCnt.value = response.data.ZipCnt;
                running.value = response.data.running;
                columns2.value = response.data.columns;
                tableData2.value = response.data.tableData;
                uploadedRows.value = [];
            } catch (error) {
                ElMessage({ type: 'error', message: '获取信息失败，请重试', })
            }
            loading.value = false;
        });

        const handleCmdUsingXmlTool = async (index, row) => {
            try {

                const useBaseURL = (radio1.value === 'CN Server') ? true : false;

                await CmdUsingXmlTool(useBaseURL);
                ElMessage({ type: 'success', message: '成功发送打包请求！！！', })

            } catch (error) {
                ElMessage({ type: 'error', message: '打包失败，请重试', })
            }
        };


        const handleDeleteRecord = async (index, row) => {
            try {
                if (row.exist == "否") {
                    return ElMessage({ type: 'error', message: '数据不存在!!!', });
                }

                const value = row.Vin;
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;
                btLoading.value = true;
                await deleteVinUploadInfo(useBaseURL, value);
                ElMessage({ type: 'success', message: '记录已清除！！！', })

            } catch (error) {
                ElMessage({ type: 'error', message: '删除失败，请重试', })
            }
            finally {
                btLoading.value = false;
            }
        };

        const handleDownLoad = async (index, row) => {
            try {
                const value = row.zip;
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;
                btLoading2.value = true;
                await DownLoadZip(useBaseURL, value);

                ElMessage({ type: 'success', message: '开始请求下载资源', })
            } catch (error) {
                ElMessage({ type: 'error', message: '请求资源失败，请重试', })
            }
            finally {
                btLoading2.value = false;
            }
        };
       
        const canUpload = (index) => {
        // 判断按钮是否可点击
            return store.state.userData?.webstatus === 'SSVIP' && !uploadedRows.value.includes(index);
        }

        const handleConfirm = async (index, row) => {
            try {
                uploadedRows.value = [...uploadedRows.value, index]
                const value = row.zip;
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;

                await MovePackedInfo(useBaseURL, value);

                ElMessage({ type: 'success', message: '移除数据成功！！', })

               
            } catch (error) {
                ElMessage({ type: 'error', message: '请求失败，请重试', })
            }
        };

        const fetchData = async () => {
            const inputvinValue = inputvin.value;
            const useBaseURL = (radio1.value === 'CN Server') ? true : false;
            if (inputvin.value.length !== 17) {
                ElMessage({ type: 'error', message: '请输入一个17位的Vin', })
                return;
            }
            loading2.value = true;  //开始加载
            const response = await getVinUploadInfo(useBaseURL, inputvinValue);

            isVisible.value = true;//显示VIN是否上传一栏
            columns.value = response.data.columns;
            tableData.value = response.data.tableData;

            try {
                if (inputvin.value.length === 17) {
                    updateRecentQueries(inputvinValue);
                }
            } catch (error) {
                alert('请求失败，请重试');
            } finally {
                loading2.value = false;
            }
        };

        // 更新查询记录的函数
        const updateRecentQueries = (query) => {
            // 检查查询是否已存在于数组中，注意这里要比较对象的 value 属性  
            const index = recentQueries.value.findIndex(item => item.value === query);
            if (index !== -1) {
                // 如果已存在，则将其从数组中移除  
                recentQueries.value.splice(index, 1);
            }
            // 将查询（作为对象）添加到数组头部，并确保数组长度不超过3
            recentQueries.value.unshift({ value: query }); // 使用unshift而不是push  
            if (recentQueries.value.length > 3) {
                recentQueries.value.pop(); // 使用pop移除末尾元素，而不是shift  
            }

            localStorage.setItem('recentQueriesDataSwitch', JSON.stringify(recentQueries.value));
        };

        // 在 handleSelect 函数中，用户选择建议后更新最近查询记录  
        const handleSelect = (item) => {
            updateRecentQueries(item.value);
            // ... 其他处理选择的代码 ...  
        };


        // 将 recentQueries 添加到 fetch-suggestions 中  
        const querySearch = (queryString, cb) => {

            cb(recentQueries.value);
        };
        return {
            userData, ElNotification,
            columns, tableData, columns2, tableData2,
            currentPage,
            pageSize,
            paginatedData,
            inputvin, recentQueries,
            isValidLength,
            fetchData,
            loading, loading2, btLoading, btLoading2,
            radio1,
            cityData,
            querySearch, search,
            handleSelect,
            handleClick, handleDownLoad, handleConfirm,canUpload, handleDeleteRecord, handleCmdUsingXmlTool,
            FolderAdd, DownLoadZip,
            PendingCnt, PackedCnt, UploadedCnt,ZipCnt,running,
            isVisible,uploadedRows
        };
    },
};
</script>



<style scoped>
/* 数据显示栏的样式 */
.main-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.header-container {
    text-align: center;
}

.el-table {
    border: 1px solid #ebeef5;
    border-radius: 4px;
}

.el-table th,
.el-table td {
    text-align: left;
    padding: 12px 20px;
}

.serial-number-container {
    width: 350px;
    /* margin-top: 1px; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.demo-tabs>.el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
}

.el-col {
    text-align: center;
}

.conan-title {
    font-size: 0.9rem;
    color: rgba(255, 0, 0, 0.81);
    text-align: center;
    margin: 20px 0;
    transition: transform 0.3s ease;
    font-family: "Poppins", sans-serif;
    text-transform: none;
}
</style>