<template>
    <el-drawer v-model="localVisible" title="更新内容" :with-header="false" size="30%" :before-close="handleClose" >
        <el-timeline>
            <el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon"
                :type="activity.type" :color="activity.color" :size="activity.size" :hollow="activity.hollow"
                :timestamp="activity.timestamp">
                {{ activity.content }}
            </el-timeline-item>
        </el-timeline>
    </el-drawer>
</template>

<script>
import { ref, watch } from 'vue';
import { MoreFilled } from '@element-plus/icons-vue'
export default {
    props: {
        modelValue: {
            type: Boolean
        }
    },
    setup(props, { emit }) {
        const activities = ref([
            {
                content: '初步建站,实现国内,海外在线诊断日志查询',
                timestamp: '2024-09-21',
                size: 'large',
                type: 'primary',
                icon: MoreFilled,
            },
            {
                content: 'new:测试接头查询,添加,删除功能',
                timestamp: '2024-09-25',
                color: '#0bbd87',
            },
            {
                content: 'new:黑名单接头查询,添加,删除功能',
                timestamp: '2024-09-27',
                color: '#0bbd87',
            },
            {
                content: 'bug:修复登录认证',
                timestamp: '2024-10-08',
                color: '#ff0000',
            },
            {
                content: 'new:外码查询,下载外码文件功能',
                timestamp: '2024-10-08',
                color: '#0bbd87',
            },
            {
                content: 'update:日志查询输入框添加3行历史纪录',
                timestamp: '2024-10-10',
                color: '#f89898',
            },
            {
                content: 'new:在线接口类型解析',
                timestamp: '2024-10-10',
                color: '#0bbd87',
            },
            {
                content: 'change:修改测试接头菜单过滤查找为SN',
                timestamp: '2024-10-16',
                color: '#E6A23C',
            },
            {
                content: 'bug:修复使用过程中,token失效未返回登录页面',
                timestamp: '2024-10-17',
                color: '#ff0000',
            },
            {
                content: 'new:VIN数据下载情况展示',
                timestamp: '2024-10-17',
                color: '#0bbd87',
            },
            {
                content: 'new:服务器中存在访问记录的VIN,提供爬取Request按钮(工作期间开放爬取)',
                timestamp: '2024-10-21',
                color: '#0bbd87',
            },
            {
                content: 'new:服务器中存在访问记录的VIN,提供下载VM文件按钮(状态为OK即可下载)',
                timestamp: '2024-10-22',
                color: '#0bbd87',
            },
            {
                content: 'bug:修复黑名单接头查询不全的问题',
                timestamp: '2024-10-30',
                color: '#ff0000',
            },
            {
                content: 'update:日志查询添加Vin参数',
                timestamp: '2024-12-09',
                color: '#f89898',
            },
            {
                content: 'change:黑名单接头删除后,清空该接头累计数据',
                timestamp: '2024-12-09',
                color: '#E6A23C',
            },
            {
                content: 'update:添加黑名单接头时,支持填写封号原因',
                timestamp: '2024-12-12',
                color: '#f89898',
            },
            {
                content: 'update:VIN数据一栏显示账号登录状态',
                timestamp: '2024-12-16',
                color: '#f89898',
            },   
            {
                content: 'update:VIN数据一栏搜索时显示模板，并提供PC调试用的加密文件下载',
                timestamp: '2024-12-18',
                color: '#f89898',
            },   
            {
                content: 'new:数据转存公司服务器,分为打包,下载,标记上传三部分.(操作权限仅开放给维护人员)',
                timestamp: '2024-12-24',
                color: '#0bbd87',
            },   
            {
                content: 'new:根据VIN前11位查询防盗快捷版模式.(操作权限仅开放给开发人员) by:LYJ',
                timestamp: '2024-12-26',
                color: '#0bbd87',
            },   
            {
                content: 'update:测试接头菜单添加仅模板测试按钮',
                timestamp: '2025-01-16',
                color: '#f89898',
            }
        ]);


        // 创建一个本地响应式变量来控制抽屉的显示状态  
        const localVisible = ref(props.modelValue);

        // 监听 props.visible 的变化，并更新 localVisible  
        watch(() => props.modelValue, (newValue) => {
            localVisible.value = newValue;
        });

        // 处理抽屉关闭事件  
        const handleClose = (done) => {
            // 可以选择添加确认关闭的逻辑  
            emit('update:modelValue', false); // 关闭抽屉，并通知父组件  
            done();
        };

        // 返回给模板的响应式数据和方法  
        return {
            localVisible,
            handleClose,
            MoreFilled,
            activities
        };
    }
};
</script>