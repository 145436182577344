<template>
  <el-row class="tac">
    <el-col :span="24">
      <h5 class="conan-title">站点最近更新 : 2025-01-16</h5>
      <el-menu default-active="1" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
        <el-menu-item index="1" @click="handleNavClick('DataDisplay')">
          <el-icon><Tickets /></el-icon>
          <span>在线诊断日志</span>
        </el-menu-item>
        <el-menu-item index="2" @click="handleNavClick('TestSn')">
          <el-icon><Avatar /></el-icon>
          <span>测试接头</span>
        </el-menu-item>
        <el-menu-item index="3" @click="handleNavClick('OutCode')">
          <el-icon><Key /></el-icon>
          <span>防盗内码</span>
        </el-menu-item>
        <el-menu-item index="4" @click="handleNavClick('BlackSn')">
          <el-icon><Lock /></el-icon>
          <span>黑名单</span>
        </el-menu-item>
        <el-menu-item index="5" @click="handleNavClick('VinList')">
          <el-icon><Star /></el-icon>
          <span>VIN数据</span>
        </el-menu-item>
        <el-menu-item index="6" @click="handleNavClick('DataSwitch')">
          <el-icon><Connection /></el-icon>
          <span>数据转存</span>
        </el-menu-item>
        <el-menu-item index="7" @click="handleNavClick('VinIMMO')">
          <el-icon><CollectionTag /></el-icon>
          <span>防盗VIN数据</span>
        </el-menu-item>
      </el-menu>
    </el-col>
    <!-- 删除了 Custom colors 部分的代码 -->
  </el-row>
</template>

<script>
// 导入 Element Plus 的图标组件  
import { Key, Avatar, Tickets, Lock, Star,Connection,CollectionTag} from '@element-plus/icons-vue';

export default {
  name: 'NavBar', // 替换为您的组件名称  
  components: {
    Key,
    Avatar,
    Tickets,
    Lock,
    Star,
    Connection,
    CollectionTag
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleNavClick(navName) {
      this.$emit('nav-click', { name: navName });// 触发父组件的 nav-click 事件，并传递信息    
    },
  },
};
</script>

<style scoped>
.conan-title {
  font-size: 0.9rem;
  color: rgba(255, 0, 0, 0.81);
  text-align: center;
  margin: 20px 0;
  transition: transform 0.3s ease;
  font-family: "Poppins", sans-serif;
  text-transform: none;
}

.conan-title:hover {
  transform: scale(1.30);
}



</style>