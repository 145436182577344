<template>    
  <el-container class="login-container">    
    <el-header>    
      <h2>福特数据库查询</h2>    
    </el-header>    
    <el-main>    
      <el-form :model="loginForm" :rules="loginRules" ref="loginFormRef" label-width="80px">    
        <el-form-item label="用户名" prop="username">    
          <el-input v-model="loginForm.username" prefix-icon="el-icon-user" />    
        </el-form-item>    
        <el-form-item label="密码" prop="password">    
          <el-input v-model="loginForm.password" prefix-icon="el-icon-lock" type="password" />    
        </el-form-item>    
        <el-form-item>    
          <el-button type="primary" @click="handleLogin">登录</el-button>  
          <el-button type="success" @click="handleRegister">注册</el-button>     
        </el-form-item>    
      </el-form>    
    </el-main>    
  </el-container>    
</template>    
      
<script>    
import { ref } from 'vue';    
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';    
import { userLogin } from '../api/users'; // 导入 userLogin 函数  
import { useRouter } from 'vue-router';  

export default {    
  name: 'LoginView',    
  setup() {    
    const store = useStore();
    const router = useRouter(); // 调用 useRouter 获取路由实例
    
    const loginForm = ref({    
      username: '',    
      password: '',    
    });    
    
    const loginRules = {    
      username: [    
        { required: true, message: '请输入用户名', trigger: 'blur' },    
      ],    
      password: [    
        { required: true, message: '请输入密码', trigger: 'blur' },    
      ],    
    };    
    
    const loginFormRef = ref(null); // 修改 ref 的命名，以避免与 loginForm 混淆  
    
    const handleLogin = () => {    
      loginFormRef.value.validate((valid) => {    
        if (valid) {    
          userLogin(loginForm.value) // 调用 userLogin 函数  
            .then(response => {  
              // 处理登录成功的响应  
              ElMessage.success('登录成功！');  
              localStorage.setItem('authToken', response.data.token);            
              store.commit('setUserData', response.data.userInfo); // 使用 Vuex Store 的 commit 方法更新状态  
              
             router.push({ path: '/'});  //跳转页面 - skipAuth:通过跳转的不再验证, query: { skipAuth: 'true' } 
            })  
            .catch(error => {  
              // 处理登录失败的响应  
              ElMessage.error('登录失败，请检查输入或网络连接！');  
              //console.error(error); // 打印错误信息到控制台  
            });  
        } else {    
          ElMessage.error('登录失败，请检查输入！');    
          return false;    
        }    
      });    
    };    
    
    const handleRegister = () => {  
      ElMessage.error('暂不支持！请联系管理员开通');   
    };


    return {    
      loginForm,    
      loginRules,    
      loginFormRef, // 返回修改后的 ref  
      handleLogin,    
      handleRegister
    };    
  },    
};    
</script>    
      
<style scoped>    
.login-container {    
  width: 400px;    
  margin: 100px auto;    
  padding: 5px;    
  border: 1px solid #dcdfe6;    
  border-radius: 8px;    
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);    
}    
      
.el-header {    
  text-align: center;    
  margin-bottom: 20px;    
}    

</style>