<template>
    <el-row :gutter="0" style="width: 600px">
        <el-col :span="1"></el-col>
        <el-col :span="23">
            <el-button type="primary" :icon="Edit" size="small" @click="open">添加测试接头</el-button>
        </el-col>

    </el-row>

    <el-row :gutter="0" style="width: 710px">
        <el-col :span="1"></el-col>
        <el-col :span="23">
            <el-table :data="filterTableData">
                <el-table-column v-for="column in columns" :key="column.prop" :prop="column.prop" :label="column.label"
                    :width="column.width" header-align="center" align="center">
                    <template #default="scope">
                        <span v-if="column.prop === 'temp'" :style="{
                            color: scope.row.temp === 'True' ? '#61bc84' : '#fd5732',
                            fontFamily: 'Poppins, Arial, sans-serif',
                            fontWeight: 'bold',
                            fontSize: '15px',
                            fontStyle: 'italic'
                        }">
                            {{ scope.row.temp }}
                        </span>
                        <span v-else>
                            {{ scope.row[column.prop] }}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column>
                    <template #header>
                        <el-input v-model="search" size="small" placeholder="测试接头筛选" />
                    </template>
                    <template #default="scope">

                        <el-popconfirm title="确认删除?" @confirm="handleDelete(scope.$index, scope.row)">
                            <template #reference>
                                <el-button size="small" type="danger">Delete</el-button>
                            </template>
                        </el-popconfirm>

                        <el-popconfirm v-if="scope.row.temp === 'False'" title="确认设置仅模板?" @confirm="setTemp(scope.row)">
                            <template #reference>
                                <el-button size="small" type="primary">SetTemp</el-button>
                            </template>
                        </el-popconfirm>
                        <el-popconfirm v-else title="确认删除仅模板?" @confirm="delTemp(scope.row)">
                            <template #reference>
                                <el-button size="small" type="danger"
                                    style="background-color: #FF6600;">DelTemp</el-button>
                            </template>
                        </el-popconfirm>

                    </template>
                </el-table-column>
            </el-table>
        </el-col>
    </el-row>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { Edit } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getTestSn, addTestSn, deleteTestSn, addTestTempSn, deleteTestTempSn } from '../api/users';
export default {
    setup() {
        // 列配置，这里你可以根据需要动态设置或静态定义  
        const search = ref('');
        const columns = ref([
            { prop: 'sn', label: 'Sn', width: '125' },
            { prop: 'type', label: '产品类型', width: '180' },
            { prop: 'com', label: 'com', width: '100' },
        ]);
        const tableData = ref([]);

        const filterTableData = computed(() =>
            tableData.value.filter(
                (data) =>
                    !search.value ||
                    data.sn.toLowerCase().includes(search.value.toLowerCase())
            )
        );

        // 组件挂载时发起请求  
        onMounted(async () => {
            try {
                // 加载列配置 ，表格数据
                getTestSn()
                    .then(response => {
                        // 处理登录成功的响应        
                        columns.value = response.data.columns;
                        tableData.value = response.data.tableData;
                        ElMessage.success('查询成功！');
                    })
                    .catch(error => {
                        // 处理登录失败的响应  
                        ElMessage.error('查询测试接头失败！');
                    });
            } catch (error) {
                ElMessage({ type: 'error', message: '查询测试接头失败，请稍后重试' });
            }
        });

        const setTemp = async (row) => {
            try {
                const value = row.sn;
                const serialNumberValue = { sn: value };
                await addTestTempSn(serialNumberValue);
                ElMessage({ type: 'success', message: '已同步到CN/US服务器中', })
                row.temp = 'True';
            } catch (error) {
                ElMessage({ type: 'warning', message: '添加模板失败，请重试', })
            }
        };
        const delTemp = async (row) => {
            try {
                const value = row.sn;
                await deleteTestTempSn(value);
                ElMessage({ type: 'success', message: '已同步到CN/US服务器中', })
                row.temp = 'False';
            } catch (error) {
                ElMessage({ type: 'warning', message: '删除模板失败，请重试', })
            }
        };
        const handleDelete = async (index, row) => {
            try {
                const value = row.sn;
                await deleteTestSn(value);
                ElMessage({ type: 'success', message: '已同步到CN/US服务器中', })
                tableData.value.splice(index, 1);
            } catch (error) {
                ElMessage({ type: 'warning', message: '删除失败，请重试', })
            }
        };

        const open = () => {
            ElMessageBox.prompt('提交测试接头到服务器中', '提交', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                inputPattern: /^.{12}$/,
                inputErrorMessage: '需满足12位有效字符',
            })
                .then(async ({ value }) => {
                    try {
                        const serialNumberValue = { sn: value };
                        await addTestSn(serialNumberValue);
                        ElMessage({ type: 'success', message: '已同步到CN/US服务器中', })
                    } catch (error) {
                        ElMessage({ type: 'warning', message: '请求失败，请重试', })
                    }
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消输入',
                    })
                })
        }
        // 返回响应式数据和函数，使它们在模板中可用  
        return {
            search,
            columns, filterTableData, tableData,
            //handleEdit,
            handleDelete, setTemp, delTemp,
            Edit,
            open, ElMessage, ElMessageBox
        };
    }
};
</script>



<style scoped></style>