<template>
  <div id="app">
    <h1>防盗快捷版VIN查询</h1>

    <!-- 查询区域（包括 VIN 查询和添加按钮）-->
    <div class="search-container">
      <div class="form-group">
        <label for="searchVIN">查询VIN(前 11 位): </label>
        <!-- v-model 用于双向数据绑定，将输入框的值与 searchVIN 变量关联 -->
        <input v-model="searchVIN" id="searchVIN" type="text" class="form-control" placeholder="输入 VIN 前 11 位进行查询"
          maxlength="11" @input="onVINInput" list="vinHistoryList" />
      </div>
      <div class="button-container">
        <!-- 查询按钮 -->
        <el-button @click="fetchData" type="primary" :disabled="loading" class="ml-3">查询</el-button>

        <!-- 添加按钮 -->
        <el-button size="small" type="success" :loading="btLoading2" :disabled="currentUserRole !== '开发'"
          @click="openForm" class="ml-3" style="min-width: 50px;">添加</el-button>

        <!-- 批量插入按钮 -->
        <el-tooltip v-show="!btLoading2"
          :content="'请上传一个格式正确的 .txt 文件，文件内容应包含 VIN、REGION 等信息。每行数据应由 8 个字段和分隔符\t组成。如:1F0?K8F??BG\tBBBB0078\tAAAA00A3\t1997-2005\tAAAA0007\tNULL\tBBBB0071\tAA54'"
          placement="bottom" effect="dark">
          <el-button size="small" type="warning" :disabled="currentUserRole !== '开发' || btLoading2"
            @click="openFileInput" class="btn btn-info ml-3" style="min-width: 50px;">
            {{ btLoading2 ? '正在插入...' : '批量插入' }}
          </el-button>
        </el-tooltip>

        <!-- 文件选择框 -->
        <input ref="fileInput" type="file" @change="handleFileChange" style="display:none;" accept=".txt" />
      </div>
    </div>

    <!-- 显示加载状态 -->
    <div v-if="loading" class="loading">加载中...</div>
    <!-- 如果没有加载数据，并且用户请求参数为空，且有搜索的 VIN，则显示未找到数据的提示 -->
    <div v-if="!loading && !userRequestParameters.length && searchVIN">点击查找该 VIN 的数据</div>
    <!-- 当没有加载数据，并且用户请求参数为空，同时没有输入搜索的 VIN 时，显示提示信息，让用户输入 VIN -->
    <div v-if="!loading && !userRequestParameters.length && !searchVIN">请输入 VIN 后查询数据</div>

    <!-- 数据表格 -->
    <table v-if="userRequestParameters.length && !loading" class="table table-striped">
      <thead>
        <tr>
          <th>VIN</th>
          <th>Region</th>
          <th>Vehicle</th>
          <th>Year</th>
          <th>YearRange</th>
          <th>Feature</th>
          <th>ModeText</th>
          <th>Mode</th>
          <th>CreatedAt</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in userRequestParameters" :key="item.ID">
          <td>{{ item.VIN }}</td>
          <td>{{ item.REGION }}</td>
          <td>{{ item.Vehicle }}</td>
          <td>{{ item.Year }}</td>
          <td>{{ item.YearRange }}</td>
          <td>{{ item.Feature }}</td>
          <td>{{ item.ModeText }}</td>
          <td>{{ item.Mode }}</td>
          <td>{{ item.CreatedAt }}</td>
          <td>
            <el-button size="small" type="success" :loading="btLoading2" :disabled="currentUserRole !== '开发'"
              @click="editItem(item)" class="btn btn-warning btn-sm" style="min-width: 50px;">编辑</el-button>
            <el-button size="small" type="success" :loading="btLoading2" :disabled="currentUserRole !== '开发'"
              @click="deleteItem(item.ID)" class="btn btn-danger btn-sm" style="min-width: 50px;">删除</el-button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- 表单区域（弹窗） -->
    <div v-if="showForm" class="form-container-modal">
      <!-- Modal背景层，点击时关闭表单 -->
      <div class="modal-overlay" @click="closeForm"></div>
      <!-- Modal内容区域 -->
      <div class="modal-content">
        <!-- 表单标题，动态显示是添加还是更新 -->
        <h2>{{ formMode }} VIN数据</h2>
        <!-- 表单开始 -->
        <form @submit.prevent="submitForm">
          <!-- 表单字段 -->
          <div class="form-group">
            <!-- VIN字段 -->
            <label for="VIN">VIN <span class="text-danger">*</span>:</label>
            <input v-model="form.VIN" id="VIN" type="text" class="form-control" :class="{ 'is-invalid': errors.VIN }"
              maxlength="11" placeholder="请输入 VIN（前 11 位）" />
            <!-- 错误信息，若VIN字段存在错误则显示 -->
            <div v-if="errors.VIN" class="invalid-feedback">{{ errors.VIN }}</div>
          </div>

          <div class="form-group">
            <label for="REGION">Region <span class="text-danger">*</span>:</label>
            <input v-model="form.REGION" id="REGION" type="text" class="form-control"
              :class="{ 'is-invalid': errors.REGION }" maxlength="8" placeholder="请输入区域" />
            <div v-if="errors.REGION" class="invalid-feedback">{{ errors.REGION }}</div>
          </div>

          <div class="form-group">
            <label for="Vehicle">Vehicle <span class="text-danger">*</span>:</label>
            <input v-model="form.Vehicle" id="Vehicle" type="text" class="form-control"
              :class="{ 'is-invalid': errors.Vehicle }" maxlength="8" placeholder="请输入车辆信息" />
            <div v-if="errors.Vehicle" class="invalid-feedback">{{ errors.Vehicle }}</div>
          </div>

          <div class="form-group">
            <label for="Year">Year <span class="text-danger">*</span>:</label>
            <input v-model="form.Year" id="Year" type="text" class="form-control" :class="{ 'is-invalid': errors.Year }"
              maxlength="9" placeholder="请输入年份" />
            <div v-if="errors.Year" class="invalid-feedback">{{ errors.Year }}</div>
          </div>

          <div class="form-group">
            <label for="YearRange">YearRange:</label>
            <input v-model="form.YearRange" id="YearRange" type="text" class="form-control"
              :class="{ 'is-invalid': errors.YearRange }" maxlength="8" placeholder="请输入年份文本ID" />
            <div v-if="errors.YearRange" class="invalid-feedback">{{ errors.YearRange }}</div>
          </div>

          <div class="form-group">
            <label for="Feature">Feature:</label>
            <input v-model="form.Feature" id="Feature" type="text" class="form-control"
              :class="{ 'is-invalid': errors.Feature }" maxlength="8" placeholder="请输入特性" />
            <div v-if="errors.Feature" class="invalid-feedback">{{ errors.Feature }}</div>
          </div>

          <div class="form-group">
            <label for="ModeText">ModeText <span class="text-danger">*</span>:</label>
            <input v-model="form.ModeText" id="ModeText" type="text" class="form-control"
              :class="{ 'is-invalid': errors.ModeText }" maxlength="8" placeholder="请输入模式文本" />
            <div v-if="errors.ModeText" class="invalid-feedback">{{ errors.ModeText }}</div>
          </div>

          <div class="form-group">
            <label for="Mode">Mode <span class="text-danger">*</span>:</label>
            <input v-model="form.Mode" id="Mode" type="text" class="form-control" :class="{ 'is-invalid': errors.Mode }"
              maxlength="4" placeholder="请输入模式" />
            <div v-if="errors.Mode" class="invalid-feedback">{{ errors.Mode }}</div>
          </div>

          <div class="form-actions">
            <button type="submit" class="btn btn-success">{{ formMode }} </button>
            <button @click="closeForm" type="button" class="btn btn-secondary">取消</button>
          </div>
        </form>
      </div>
    </div>
    <datalist id="vinHistoryList">
      <option v-for="vin in vinHistory" :key="vin" :value="vin" />
    </datalist>
  </div>
</template>

<script>
import { addImmoMode, updateImmoMode, getImmoMode, deleteImmoMode } from '../api/users';
import { useStore } from 'vuex';

export default {
  data() {
    return {

      userRequestParameters: [],
      loading: false,
      searchVIN: '',
      vinHistory: [],  // 用于存储 VIN 历史记录
      form: {
        VIN: '',
        REGION: '',
        Vehicle: '',
        Year: '',
        YearRange: '',
        Feature: '',
        ModeText: '',
        Mode: ''
      },
      formMode: '添加',
      currentID: null,
      showForm: false,
      currentUserRole: '', // 存储当前用户角色
      btLoading2: false,
      errors: {}
    };
  },
  methods: {

    // 获取数据（根据 VIN 查询）
    async fetchData() {
      // 仅在点击查询按钮时进行 VIN 长度检查
      if (this.searchVIN && this.searchVIN.length !== 11) {
        alert('输入的 VIN 必须为 11 位！');  // 提示用户 VIN 长度不正确
        return;  // 退出方法，不再进行查询
      }

      if (!this.vinHistory.includes(this.searchVIN)) {
        this.vinHistory.push(this.searchVIN);  // 将 VIN 保存到历史记录

        // 保证历史记录最多保存 10 个
        if (this.vinHistory.length > 10) {
          this.vinHistory.shift();  // 保持最多 10 个历史记录
        }

        // 将历史记录保存到 localStorage
        localStorage.setItem('vinHistory', JSON.stringify(this.vinHistory));
      }
      this.loading = true;// 设置加载状态为 true，表示数据正在加载
      try {
        if (!this.searchVIN) { // 如果没有输入 VIN
          this.userRequestParameters = [];// 清空用户请求参数
          this.loading = false; // 设置加载状态为 false
          return;
        }

        const response = await getImmoMode(this.searchVIN.slice(0, 11));
        this.userRequestParameters = response.data.tableData;// 将响应数据赋值给 userRequestParameters

        if (this.userRequestParameters.length === 0 && this.searchVIN) {
          alert('没有找到匹配的 VIN 数据');
        }
      } catch (error) {// 捕获可能发生的错误
        console.error(error);// 在控制台输出错误信息
        alert('加载数据失败');
      } finally {
        this.loading = false; // 无论成功或失败，最后都将加载状态设为 false
      }
    },

    async deleteItem(id) {
      if (confirm('确定删除此条记录吗？')) {
        try {
          await deleteImmoMode(id);
          this.fetchData();
          alert('删除成功');
        } catch (error) {
          alert('删除失败');
          console.error(error);
        }
      }
    },

    editItem(item) {
      this.formMode = '修改';
      this.form = { ...item };
      this.currentID = item.ID;
      this.showForm = true;
    },

    // 监听 VIN 输入，保存历史记录
    onVINInput() {
      this.searchVIN = event.target.value.toUpperCase();//将输入的vin转换为大写
    },

    // 加载 VIN 历史记录
    loadVINHistory() {
      const savedHistory = localStorage.getItem('vinHistory');
      if (savedHistory) {
        this.vinHistory = JSON.parse(savedHistory);  // 从 localStorage 加载历史记录
      }
    },
    // 校验表单
    validateForm() {
      this.form.VIN = this.form.VIN.toUpperCase();//转换成大写
      this.form.REGION = this.form.REGION.toUpperCase();//转换成大写
      this.form.Vehicle = this.form.Vehicle.toUpperCase();//转换成大写
      this.form.Year = this.form.Year.toUpperCase();//转换成大写
      this.form.YearRange = this.form.YearRange.toUpperCase();//转换成大写
      this.form.Feature = this.form.Feature.toUpperCase();//转换成大写
      this.form.ModeText = this.form.ModeText.toUpperCase();//转换成大写
      this.form.Mode = this.form.Mode.toUpperCase();//转换成大写

      this.errors = {};
      const regex = {
        VIN: /^[A-HJ-NPR-Z0-9?]{11}$/,
        REGION: /^[0-9A-F]{8}$/,
        Vehicle: /^[0-9A-F]{8}$/,
        Year: /^(\d{4}|-\d{4}|\d{4}-\d{4}|\d{4}-$|^)$/,  // 修改后的正则表达式，允许空值
        YearRange: /^[0-9A-F]{8}$/,
        Feature: /^[0-9A-F]{8}$/,
        ModeText: /^[0-9A-F]{8}$/,
        Mode: /^[0-9A-F]{4}$/
      };

      if (!this.form.VIN || !regex.VIN.test(this.form.VIN)) {
        this.errors.VIN = 'VIN 必须是 11 位的合法字符串';
      }
      if (!this.form.REGION || !regex.REGION.test(this.form.REGION)) {
        this.errors.REGION = 'Region 必须是 8 位的数字或字母（0-9, A-F）的文本ID';
      }
      if (!this.form.Vehicle || !regex.Vehicle.test(this.form.Vehicle)) {
        this.errors.Vehicle = 'Vehicle 必须是 8 位的数字或字母（0-9, A-F）的文本ID';
      }
      if (this.form.Year !== "NULL" && this.form.Year !== "" && !regex.Year.test(this.form.Year)) {
        this.errors.Year = 'Year 必须是 4 位数字，或者年份范围（例如 2011 或 2011-2015，2011-，或者为空）';
      }
      if (this.form.YearRange && !regex.YearRange.test(this.form.YearRange)) {
        this.errors.YearRange = 'YearRange 必须是 8 位的数字或字母（0-9, A-F）的文本ID';
      }
      // 如果 Feature 字段不为空且不符合正则
      if (this.form.Feature !== "NULL" && this.form.Feature !== "" && !regex.Feature.test(this.form.Feature)) {
        this.errors.Feature = 'Feature 必须是 8 位的数字或字母（0-9, A-F）的文本ID，或者为空';
      }
      if (!this.form.ModeText || !regex.ModeText.test(this.form.ModeText)) {
        this.errors.ModeText = 'ModeText 必须是 8 位的数字或字母（0-9, A-F）的文本ID';
      }
      if (!this.form.Mode || !regex.Mode.test(this.form.Mode)) {
        this.errors.Mode = 'Mode 必须是 4 位的数字或字母（0-9, A-F）的快捷版模式号';
      }

      return Object.keys(this.errors).length === 0;
    },
    // 提交表单
    async submitForm() {
      if (this.validateForm()) {
        try {
          // 如果 Year 字段为空，则赋值为 "NULL"
          if (!this.form.Year || this.form.Year === "NULL") {
            this.form.Year = "NULL";
            this.form.YearRange = "AAAA00F6" // 如果 Year 字段为空，YearRange则赋予默认值AAAA00DB；
          }
          // 如果 Feature 字段为空，则赋值为 "NULL"
          if (!this.form.Feature) {
            this.form.Feature = "NULL";
          }

          if (this.formMode === '添加')
            await addImmoMode(this.form);
          else
            await updateImmoMode(this.form);

          this.fetchData();
          this.closeForm();
          alert(`${this.formMode}成功`);
        } catch (error) {
          alert(`${this.formMode}失败`);
          console.error('Error during submission:', error);
        }
      } else {
        alert('表单验证失败，请检查输入');
      }
    },

    openForm() {
      this.formMode = '添加';
      this.form = {
        VIN: '',
        REGION: '',
        Vehicle: '',
        Year: '',
        YearRange: '',
        Feature: '',
        ModeText: '',
        Mode: ''
      };
      this.showForm = true;
    },

    closeForm() {
      this.showForm = false;
    },

    // 打开文件选择框
    openFileInput() {
      if (this.btLoading2) return;  // 如果正在加载，阻止进一步操作

      // 清空文件输入框，确保可以重复选择相同的文件
      this.$refs.fileInput.value = null;

      // 确保 DOM 渲染完成后再触发点击
      this.$nextTick(() => {
        this.$refs.fileInput.click();  // 触发文件选择框
      });
    },

    // 处理文件选择
    async handleFileChange(event) {
      this.btLoading2 = true;  // 设置按钮为加载状态
      const file = event.target.files[0];
      if (!file) {
        this.btLoading2 = false;
        alert('请选择一个文件');
        return;
      }

      // 使用 FileReader 读取文件内容
      const reader = new FileReader();
      reader.onload = async (e) => {
        const fileContent = e.target.result;
        const records = this.parseFileContent(fileContent); // 解析文件内容为记录数组

        if (records.length === 0) {
          this.btLoading2 = false;
          alert('文件内容无效，请检查文件格式');
          return;
        }

        try {
          // 假设文件操作或批量插入操作
          await this.batchInsertData(records);
        } catch (error) {
          console.error('批量插入失败:', error);
        } finally {
          this.btLoading2 = false;  // 操作完成，重置加载状态
        }
      };
      reader.onerror = () => {
        // 处理文件读取错误时也需要重置按钮状态
        this.btLoading2 = false;
        alert('文件读取失败');
      };
      reader.readAsText(file);
    },

    // 解析文件内容
    parseFileContent(content) {
      const lines = content.trim().split('\n');  // 按行分割文件内容
      const records = [];
      const regex = {
        VIN: /^[A-HJ-NPR-Z0-9?]{11}$/,
        REGION: /^[0-9A-F]{8}$/,
        Vehicle: /^[0-9A-F]{8}$/,
        Year: /^(\d{4}|-\d{4}|\d{4}-\d{4}|\d{4}-$|^)$/,  // 修改后的正则表达式，允许空值
        YearRange: /^[0-9A-F]{8}$/,
        Feature: /^[0-9A-F]{8}$/,
        ModeText: /^[0-9A-F]{8}$/,
        Mode: /^[0-9A-F]{4}$/
      };

      let errorCount = 0;  // 连续出错的行数

      // 遍历每行数据
      for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
        const line = lines[lineIndex];
        const fields = line.split('\t').map(field => field.trim());  // 处理每行数据并分割成字段，去除空格

        // 如果行数据有8个字段
        if (fields.length === 8) {
          const [VIN, REGION, Vehicle, Year, YearRange, Feature, ModeText, Mode] = fields;

          // 将 "NULL" 转换为 null
          const record = {
            VIN,
            REGION,
            Vehicle,
            YearRange,
            Year,  // 如果 Year 为 NULL，设置为 null
            Feature,  // 如果 Feature 为 NULL，设置为 null
            ModeText,
            Mode
          };
          const lineErrors = [];

          // 如果 Year 字段为空，则赋值为 "NULL"
          if (!record.Year || record.Year === "NULL") {
            record.Year = "NULL";
            record.YearRange = "AAAA00F6"; // 如果 Year 字段为空，YearRange则赋予默认值AAAA00DB；
          }
          // 如果 Feature 字段为空，则赋值为 "NULL"
          if (!record.Feature) {
            record.Feature = "NULL";
          }

          // 转换为大写
          record.VIN = record.VIN.toUpperCase();
          record.REGION = record.REGION.toUpperCase();
          record.Vehicle = record.Vehicle.toUpperCase();
          record.Year = record.Year.toUpperCase();
          record.YearRange = record.YearRange.toUpperCase();
          record.Feature = record.Feature.toUpperCase();
          record.ModeText = record.ModeText.toUpperCase();
          record.Mode = record.Mode.toUpperCase();

          // 校验每个字段，并将错误信息收集到 `lineErrors` 数组
          if (!record.VIN || !regex.VIN.test(record.VIN)) {
            lineErrors.push(`VIN (${record.VIN}) 必须是有效的 11 位字符串`);
          }
          if (!record.REGION || !regex.REGION.test(record.REGION)) {
            lineErrors.push(`Region (${record.REGION}) 必须是 8 位的数字或字母（0-9, A-F）的文本ID`);
          }
          if (!record.Vehicle || !regex.Vehicle.test(record.Vehicle)) {
            lineErrors.push(`Vehicle (${record.Vehicle}) 必须是 8 位的数字或字母（0-9, A-F）的文本ID`);
          }
          if (record.Year !== "NULL" && record.Year !== "" && !regex.Year.test(record.Year)) {
            lineErrors.push(`Year (${record.Year}) 必须是 4 位数字，或者年份范围（例如 2011 或 2011-2015，2011-，或者为空）`);
          }
          if (record.YearRange && !regex.YearRange.test(record.YearRange)) {
            lineErrors.push(`YearRange (${record.YearRange}) 必须是 8 位的数字或字母（0-9, A-F）的文本ID`);
          }
          if (record.Feature !== "NULL" && record.Feature !== "" && !regex.Feature.test(record.Feature)) {
            lineErrors.push(`Feature (${record.Feature}) 必须是 8 位的数字或字母（0-9, A-F）的文本ID，或者为空`);
          }
          if (!record.ModeText || !regex.ModeText.test(record.ModeText)) {
            lineErrors.push(`ModeText (${record.ModeText}) 必须是 8 位的数字或字母（0-9, A-F）的文本ID`);
          }
          if (!record.Mode || !regex.Mode.test(record.Mode)) {
            lineErrors.push(`Mode (${record.Mode}) 必须是 4 位的数字或字母（0-9, A-F）的快捷版模式号`);
          }

          if (lineErrors.length > 0) {
            errorCount++;  // 错误计数器增加
            alert(`插入失败：第 ${lineIndex + 1} 行\n错误信息：\n${lineErrors.join('\n')}`);
          } else {
            // 如果没有错误，将该记录添加到 `records` 数组
            records.push(record);  // 将合法记录添加到数组中
            errorCount = 0;  // 成功插入后，重置错误计数器
          }

        } else {
          // 如果字段数不为 8，输出错误日志并弹出提示
          alert(`文件格式错误：第 ${lineIndex + 1} 行字段数不正确。请检查文件内容是否符合要求。`);
          return [];  // 返回空数组表示错误，停止后续解析
        }

        // 如果连续 10 行数据都有问题，直接停止插入
        if (errorCount >= 10) {
          alert('连续 10 条数据出错，操作已终止');
          records.length = 0;  // 清空记录
          break;  // 立即退出循环，停止后续数据处理
        }
      }

      return records;
    },



    // 批量插入数据
    async batchInsertData(records) {
      this.btLoading2 = true;
      let successCount = 0;
      let failureCount = 0;
      let failureDetails = [];  // 用于记录失败的详细信息

      try {
        // 批量插入所有记录
        try {
          await addImmoMode(records);
          successCount = records.length; // 如果没有异常，所有记录都成功插入
        } catch (error) {
          failureCount = records.length; // 如果整个操作失败，所有记录都视为失败
          console.error('Failed to insert records:', error);

          // 记录失败的详细信息
          failureDetails.push(`插入失败：错误信息：${error.message || '未知错误'}`);
        }

        // 提示成功插入的记录数量
        if (successCount > 0) {
          alert(`${successCount} 条记录插入成功`);
        }

        // 提示失败插入的记录数量，并显示详细的失败信息
        if (failureCount > 0) {
          alert(`${failureCount} 条记录插入失败\n详情:\n${failureDetails.join('\n')}`);
        }

        // 如果所有记录都成功插入
        if (successCount === records.length) {
          alert('所有记录插入成功');
        } else if (failureCount === 0) {
          alert('批量插入成功');
        } else {
          alert('批量插入结束');
        }

        this.fetchData(); // 刷新数据
      } catch (error) {
        alert('批量插入失败');
        console.error('Error during batch insert:', error);
      } finally {
        this.btLoading2 = false;
      }
    }
  },

  mounted() {
    this.loadVINHistory();  // 在页面加载时读取历史记录
    this.fetchData();
    // 获取当前用户的角色
    const store = useStore();
    this.currentUserRole = store.state.userData?.job;  // 假设 job 字段存储用户角色
  },
};
</script>


<style scoped>
/* 全局样式 */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
}

/* 标题样式 */
h1 {
  text-align: center;
  color: #303133;
  margin-bottom: 20px;

  font-size: 1.5rem;
  /* 增加字体大小 */
}

/* 查询区域样式 */
.search-container {
  display: flex;
  justify-content: center;
  /* 保证按钮水平居中 */
  align-items: center;
  /* 保证按钮垂直居中 */
  margin-bottom: 10px;
  /* 底部间距 */
}

/* 输入框区域 */
.search-container .form-group {
  display: flex;
  align-items: center;
  /* 垂直居中对齐输入框 */
  margin-right: 5px;
  /* 输入框和按钮之间的间距 */
}

/* 使输入框中的文本自动转换为大写 */
.search-container .form-group input {
  text-transform: uppercase;
}

/* 输入框的样式 */
.search-container .form-control {
  width: 200px;
  /* 控制输入框的宽度 */
  height: 38px;
  /* 确保输入框的高度与按钮一致 */
  padding: 0 10px;
  /* 添加内边距，让文本不贴边 */
  border-radius: 4px;
  /* 保持圆角 */
  border: 1px solid #ddd;
  /* 输入框的边框 */
}

.search-container button .el-button {
  margin-left: 5px;
  /* 按钮之间的间距 */
}

/* 表格样式 */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  padding: 12px;
  text-align: center;
}

th {
  background-color: #007bff;
  color: white;
}

td {
  border-bottom: 1px solid #ddd;
}

/* 按钮样式 */
.btn,
.el-button {
  padding: 5px 15px;
  /* 内边距，确保按钮大小合适 */
  font-size: 14px;
  /* 按钮字体大小 */
  height: 38px;
  /* 按钮的高度与输入框一致 */
  border-radius: 4px;
  /* 按钮的圆角 */
  cursor: pointer;
  /* 鼠标指针样式 */
  margin: 1px;
  /* 按钮之间的间距 */
  border: none;
  /* 去掉按钮的外边框 */
}

/* 按钮容器 */
.button-container {
  display: flex;
  justify-content: center;
  /* 保证按钮水平居中 */
  align-items: center;
  /* 保证按钮垂直居中 */
  margin-bottom: 10px;
  /* 底部间距 */
}

.btn-primary {
  background-color: #0056b3 !important;
  /* 禁用时保持颜色不变 */
  color: white !important;
  /* 禁用时保持颜色不变 */
}

.btn-success {
  background-color: #28a745 !important;
  /* 禁用时保持颜色不变 */
  color: white !important;
  /* 禁用时保持颜色不变 */
}

.btn-warning {
  background-color: #ffc107 !important;
  /* 禁用时保持颜色不变 */
  color: white !important;
  /* 禁用时保持颜色不变 */
}

.btn-danger {
  background-color: #dc3545 !important;
  /* 禁用时保持颜色不变 */
  color: white !important;
  /* 禁用时保持颜色不变 */
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

.loading {
  text-align: center;
  font-size: 18px;
  color: #888;
}

/* 表单区域样式 */
.form-container-modal {
  position: fixed;
  /* 使用固定定位，确保 modal 覆盖整个屏幕 */
  top: 0;
  left: 0;
  width: 100%;
  /* 宽度为整个视口宽度 */
  height: 100%;
  /* 高度为整个视口高度 */
  background: rgba(0, 0, 0, 0.2);
  /* 背景设置为透明黑色，opacity 为 0.2，创造阴影效果 */
  display: flex;
  /* 使用 flexbox 布局，使内容居中 */
  justify-content: center;
  align-items: center;
  z-index: 1050;
  /* 确保 modal 层级高于其他元素 */
}

/* 使所有表单输入框自动转换为大写字母 */
.form-container-modal input {
  text-transform: uppercase;
  /* 自动转换输入为大写字母 */
}

/* .modal-overlay 样式 */
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  /* 背景阴影 */
  z-index: 1040;
  /* 确保背景阴影在内容下方，但高于页面其他元素 */
}

/* .modal-content 样式 */
.modal-content {
  background-color: white;
  padding: 20px;
  width: 80%;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1051;
  /* 确保 modal 内容位于 overlay 上方 */
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.text-danger {
  color: red;
}

/* 输入框错误样式 */
.is-invalid {
  border-color: #dc3545;
}

.invalid-feedback {
  color: #dc3545;
  font-size: 0.875em;
  margin-top: 5px;
}
</style>