import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginPage.vue'
import HomePage from '../views/HomePage.vue'
import DataDisplayView from '@/components/DataDisplay.vue';
import TestSnView from '@/components/TestSn.vue';
import BlackSnView from '@/components/BlackSn.vue';
import OutCodeView from '@/components/OutCode.vue';
import VinListView from '@/components/VinList.vue';
import DataSwitchView from '@/components/DataSwitch.vue';
import VinIMMOView from '@/components/VinIMMO.vue';
import { isAuthenticated } from '../api/users'
const routes = [
  { path: '/login', name: 'LoginView', component: LoginView, },
  {
    path: '/', name: 'HomePage', component: HomePage, meta: { requiresAuth: true },
    children: [
      { path: '', name: 'DataDisplay', component: DataDisplayView },
      { path: 'testsn', name: 'TestSn', component: TestSnView },
      { path: 'blacksn', name: 'BlackSn', component: BlackSnView },
      { path: 'outcode', name: 'OutCode', component: OutCodeView },
      { path: 'vinlist', name: 'VinList', component: VinListView },
      { path: 'dataswitch', name: 'DataSwitch', component: DataSwitchView },
      { path: 'vinimmo', name: 'VinIMMO', component: VinIMMOView }
      // 其他子路由...  
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// 全局前置守卫  

router.beforeEach((to, from, next) => {

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);   // 检查目标路由是否需要验证
  //const skipAuth = to.query.skipAuth === 'true';// 检查是否应该跳过验证（例如，通过跳转来的） 
  //if (requiresAuth && !skipAuth) {
  if (requiresAuth) {
    isAuthenticated()
      .then(isAuth => {
        if (isAuth) {
          next(); // 用户已登录，继续正常导航
        } else {
          next({
            name: 'LoginView', // 如果用户未登录，则重定向到登录页面  
          });
        }
      });
  } else {
    next(); // 不需要验证的路由，直接通过 
  }
});


export default router
